import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/tailwind.css'
import './registerServiceWorker'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'

const app = createApp(App);
app.use(VueSweetalert2);
window.Swal =  app.config.globalProperties.$swal;

let baseUrl="https://d5b8-2401-4900-5ba4-9ce3-773f-933a-79eb-391f.ngrok-free.app/api/";
if(process.env.NODE_ENV === 'production'){
  baseUrl = "https://mayfair-glitz.apps.enrichr.co/api/";
}
axios.defaults.baseURL = baseUrl;
axios.defaults.headers = {
  'Content-Type': 'application/json',
//   Authorization: `Token token=${token}`

}


router.beforeEach((to, from, next) => {
  if(to.name === undefined){
    next({ name: "PageNotFound" });
  } else {
    next()
  }
})
app.config.globalProperties.$axios = axios;
app.config.productionTip = false

app.use(router).mount('#app')
